import { Api, ApiModel } from 'base'

class SquareMarketplacesApi extends Api {
  collectionName = 'square_marketplaces'

  async itemsList() {
    try {
      const result = await Backend.get(`/square_marketplace/items_lists`);
      return result;
    } catch (error) {
      console.error("Error fetching items:", error);
      throw error;
    }
  }

  async perform(params) {
    try {
      const result = await Backend.post(`/square_marketplace/place_order`, params);
      return result;
    } catch (error) {
      console.error("Error performing payment:", error);
      throw error;
    }
  }
}

export class Payment extends ApiModel {
  get succeeded() { return this.status === "succeeded"; }
}

export default new SquareMarketplacesApi();
