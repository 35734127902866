const API_ROOT = "/api/v1"

window.requireCustomAsset = (fileName, type) => {

  return require(`customizations/${window.FRONTEND}/${fileName}.png`)
}

window.requireCustomOrDefaultAsset = (fileName, type) => {
  try {
    if (type == 'png'){
      return require(`customizations/${window.FRONTEND}/${fileName}.png`)
    }
    else{
      return require(`customizations/${window.FRONTEND}/${fileName}.gif`)
    }
  } catch {
    if (type == 'png'){
      return require(`customizations/default/${fileName}.png`)
    }
    else{
      return require(`customizations/default/${fileName}.gif`)

    }
  }
}

window.requireCustomAssetWithFallback = (fileName, fileName2, typeofFile, typeofFile2) => {
  try {
    if (typeofFile == 'jpg'){
      return require(`customizations/${window.FRONTEND}/${fileName}.jpg`)
    }
    else{
      return require(`customizations/${window.FRONTEND}/${fileName}.gif`)

    }
    // return require(`customizations/${window.FRONTEND}/${fileName}`)
  } catch {
    if (typeofFile2 == 'jpg'){
      return require(`customizations/${window.FRONTEND}/${fileName2}.jpg`)
    }
    else{
      return require(`customizations/${window.FRONTEND}/${fileName2}.gif`)

    }
    // return require(`customizations/${window.FRONTEND}/${fileName2}`)
  }
}

window.$ = window.jQuery = require("jquery")
window.Backbone = require('backbone')
window.moment = require("moment")
window.classNames = require('classnames')
window._ = require("lodash")
window.React = require('react')
window.ReactDOM = require('react-dom')
window.PropTypes = window.PT = require('prop-types')
window.Link = require('react-router-dom').Link
window.NavLink = require('react-router-dom').NavLink
window.Redirect = require('react-router-dom').Redirect
window.Component = React.Component

const smoothscroll = require('smoothscroll-polyfill')
smoothscroll.polyfill()

require('bootstrap')
require('shared/extensions')

window.App = window.application = require('application').default

let Layout = require('layout/layout').default
document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<Layout />, document.getElementById('react'))
})


// debug
window.Backend = require('base/backend').default
window.User = require('api/user').default
window.ParkApi = require('api/park-api').default
window.EventApi = require('api/event-api').default
