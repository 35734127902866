import { Modal, withErrorBoundary, Formatter } from 'shared'
import { SquareMarketplacesApi, PaymentApi } from 'api'
import { Link } from 'react-router-dom'
import React, { Component } from 'react';

class SquareMarketplacesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: [],
      isLoading: true,
      error: null,
    };
  }

  async componentDidMount() {
    try {
      const result = await SquareMarketplacesApi.itemsList();
      if (result.success) {
        this.setState({ cartItems: result.cart_items, isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }

  render() {
    const { base } = this.props;
    const { isLoading, error, cartItems } = this.state;
    let activeSqaureButton = application.state.getObject('activeSqaureButton');
    let onClose = () => { application.state.deleteObject('activeModalType', 'activeSquareMarketplacesButton', 'paymentFor') };

    if (isLoading) {
      return <p>Loading...</p>;
    }

    return (
      <Modal title='SquareMarketplaces' opened onClose={onClose} size='normal'>
        <SquareMarketplacesModalInner smartButton={activeSqaureButton} cartItems={cartItems} base={base} onClose={onClose} />
      </Modal>
    );
  }
}

class SquareMarketplacesModalInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: props.cartItems.map(item => ({ ...item, quantity: 0 })),
      progress: false,
      done: false,
      succeeded: false,
      failed: false,
      error: null,
    };
  }
  inc = (itemId, count) => {
    const cartItems = this.state.cartItems.map(item =>
      item.id === itemId ? { ...item, quantity: Math.max(0, item.quantity + count) } : item
    );
    this.setState({ cartItems });
  }

  calculateTotalAmount = () => {
    return this.state.cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);
  }

  pay = async () => {
    const { smartButton } = this.props;
    this.setState({ progress: true });

    const cartItems = this.state.cartItems
      .filter(item => item.quantity > 0)
      .map(item => ({
        id: item.id,
        square_id: item.square_id,
        price: item.price,
        quantity: item.quantity,
      }));



    const paymentParams = {
      cart_items: cartItems,
      smart_button_id: smartButton.id,
      web_request: true
    };

    try {
      const result = await SquareMarketplacesApi.perform(paymentParams);
      if (result.success) {
        this.setState({ done: true, progress: false, succeeded: true });
      } else {
        this.setState({ done: true, progress: false, failed: true, error: result.error_message });
      }
    } catch (error) {
      this.setState({ done: true, progress: false, failed: true, error: error.message });
    }
  }

  done = () => {
    this.props.onClose();
  }

  didMovedToCardSettings = () => {
    Modal.current.hide();
  }

  render() {
    const { base = '/' } = this.props;
    const { progress, done, succeeded, failed, error } = this.state;
    const hasCard = application.user.hasCard;
    const totalAmount = this.calculateTotalAmount();

    if (!hasCard) {
      return (
        <SquareMarketplacesModalChrome>
          <p>You need to set up a credit card to perform payments.</p>
          <Link to="/settings/payment" onClick={this.didMovedToCardSettings}>Add a Credit Card</Link>
        </SquareMarketplacesModalChrome>
      );
    }

    return (
      <SquareMarketplacesModalChrome>
        {!progress && !done &&
          <>
            <table className="table">
              <tbody>
                {this.state.cartItems.map(cartItem => (
                  <tr key={cartItem.id}>
                    <td className="text-left">{cartItem.name}</td>
                    <td>{Formatter.amountFromCents(cartItem.price)}</td>
                    <td className="text-right">
                      <button type='button' className='btn btn-outline-primary btn-sm ticket-btn' onClick={() => this.inc(cartItem.id, -1)}>–</button>
                      <span className='counter'>{cartItem.quantity}</span>
                      <button type='button' className='btn btn-outline-primary btn-sm ticket-btn' onClick={() => this.inc(cartItem.id, +1)}>+</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p>
              <button className='btn btn-primary' type="button" onClick={this.pay}>Pay  {Formatter.amountFromCents(totalAmount)}</button>
            </p>
          </>
        }
        {progress && <p className="alert alert-info text-center">Processing...</p>}
        {succeeded &&
          <div>
            <p className="alert alert-info text-center">Payment completed. Thank you.</p>
            <p>
              <button className='btn btn-primary' type="button" onClick={this.done}>Done</button>
            </p>
          </div>
        }
        {failed &&
          <div>
            <p className="alert alert-danger text-center">Payment failed. {error}</p>
            <p>
              <button className='btn btn-primary' type="button" onClick={this.done}>Done</button>
            </p>
          </div>
        }
      </SquareMarketplacesModalChrome>
    );
  }
}

export default withErrorBoundary(SquareMarketplacesModal)

const SquareMarketplacesModalChrome = ({ children }) =>
  <div className="SquareMarketplacesModal">
    <div className="modal-body">
      <div className="SquareMarketplacesModal-content text-center">
        <h2 className='SquareMarketplacesModal-heading'>Sunterra Marketplace</h2>
        { children }
      </div>
    </div>
  </div>;
